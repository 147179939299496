import React from "react";

// Assets
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactUsContent from "../../components/ContactUsContent/ContactUsContent";

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <Header />
      <ContactUsContent />
      <Footer />
    </div>
  );
}

export default ContactUs;
