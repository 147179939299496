import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./AboustUsWelcome.css";
import WelcomeBanner from "../../images/about-welcome-banner.jpg";

const AboustUsWelcome = () => {

    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 

  return (
    <div className="AboustUsWelcome">
        <div className="about-welcome-left">
            <div className="section-title">
                <h1 className="title">Helping You<br />Grow in Every Stage</h1>
                <div className="line"></div>
            </div>
            <p className="text">The Unforsaken-House of Hephzibah is a professional and fully registered faith-based private Psychological facility in Lekki, Lagos State. We render qualitative Clinical Psychology services in line with experiences in Counselling, Mentoring, Psychological assessment and evaluation, Social Work, and Organizational work. </p>
            <p className="text">We also work with clients to modify behavior in a way that encourages them to maximize their potentials and minimize the distress associated with the problem. Our focus is always to help the client or child, parents, family, teacher, or school achieve the educational, social, and developmental goals initially set.  We use a variety of psychotherapeutic approaches based on assessment and we render qualitative ‘wholistic’ biopsychosocial spiritual intervention.  </p>
            <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-primary">Book a Session</div></Link>
        </div>
        <div className="about-welcome-right">
            <img src={WelcomeBanner} alt="About Us Welcome Banner" loading="lazy" className="welcome-banner" />
        </div>
    </div>
  )
}

export default AboustUsWelcome;