import React from "react";
import { NavLink as Link } from "react-router-dom";

// Assets
import "./AboutHero.css";
import AboutImage from "../../images/about-hero-banner.jpg";
import heroBG from "../../images/img-bg.svg";

const AboutHero = () => {
    // Click event that scroll to the top of the page
    const handleClickScroll = () => {
      window.scrollTo(0, 0);
    }; 
    
  return (
    <div className="landHero">
        <div className="hero-left-side">
          <div className="hero-head-text">
            About us
          </div>
          <p className="hero-text">
            We work with clients to improve their psychological well-being, coping skills and other life skills. The Specialist meets and works with the client to analyze, identify, and provide treatment plan for situational problems.
          </p>
          <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btn-secondry">Book a Session</div></Link>
        </div>
        <div className="hero-right-side">
          <img src={AboutImage} alt="About us Hero banner" loading="lazy" className="stack inner-hero" />
          <img src={heroBG} alt="Hero-BG" loading="lazy" className="stack img-bg" />
        </div>
    </div>
  )
}

export default AboutHero;